import React, { useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import MuiAlert from '@material-ui/lab/Alert';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress'
import axios from 'axios';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {' © '}
            <Link color="inherit" href="https://sandoghmu.ir/">
                صندوق قرض الحسنه موعود
      </Link>{' '}
            {new Date().getFullYear()}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        // direction:"rtl",
        textAlignLast: "center",
        textAlign: "center"

    },
    submit: {
        margin: theme.spacing(7, 18, 3),
        padding: theme.spacing(2, 6, 2)
    },
    loading: {
        margin: theme.spacing(7, 21, 3),
        padding: theme.spacing(1, 1, 1)
    },
    loader: {
        padding: '5px',
        color: 'red',
        fontSize: '1px'
    },


}));

export default function SignIn() {
    const classes = useStyles();
    const [open, setOpen] = useState(false)
    const [email, setEmail] = useState('')
    const [checkEmail, setCheckEmail] = useState(true)
    const [pass, setPass] = useState('')
    const [checkPass, setCheckPass] = useState(true)
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState('')
    const [AlertColor, setAlertColer] = useState('')






    const vertical = { vertical: 'top' }
    const snackBarClose = () => {
        setTimeout(function () {
            setOpen(false)
            setMessage('')
        }
            , 10000
        )
    }

    const submit = (e) => {
        e.preventDefault()
        setLoading(true)
        setOpen(false);
        setMessage('')
        if (email !== "" && pass !== "") {
            axios.post("https://api.sandoghmu.ir/username.php", {
                email: email,
                password: pass
            },
            ).then((res, data) => {
                if (res.data === 'matched') {
                    setAlertColer('success')
                    setCheckPass(true)
                    setCheckEmail(true)
                    setOpen(true)
                    setMessage('ورود موفقیت آمیز بود')
                    setLoading(false)
                    snackBarClose()
                } else if(res.data==='invalid') {
                    setMessage('نام کاربری یا گذرواژه اشتباه است')
                    setAlertColer('error')
                    setCheckPass(false)
                    setCheckEmail(false)
                    setOpen(true)
                    setLoading(false)
                    snackBarClose()
                }else{
                    setMessage(res.data)
                    setAlertColer('error')
                    setCheckPass(false)
                    setCheckEmail(false)
                    setOpen(true)
                    setLoading(false)
                    snackBarClose()  
                }
            }).catch(e => {
                setMessage('خطا در دریافت اطلاعات')
                setAlertColer('error')
                setLoading(false)
                setOpen(true)
                snackBarClose()
            })
        } else {
            setAlertColer('error')
            setMessage('همه ورودی ها باید پر شوند')
            setOpen(true)
            setLoading(false)
            snackBarClose()

        }


    }

    return (
        <Container component="main" maxWidth="xs">
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                // TransitionComponent='TransitionRight'
                open={open}
                message={message}
            >
                <Alert severity={AlertColor}>
                    {message}
                </Alert>
            </Snackbar>

            <CssBaseline />
            <div className={classes.paper} >
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    ورود
                </Typography>
                <form className={classes.form} >
                    <TextField
                        error={checkEmail ? false : true}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        placeholder="ایمیل"
                        name="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        autoComplete="email"
                        autoFocus
                        className={classes.input}
                        onBlur={() => email === '' ? setCheckEmail(false) : setCheckEmail(true)}
                    />
                    <TextField
                        error={checkPass ? false : true}
                        onChange={e => setPass(e.target.value)}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        placeholder="پسورد"
                        type="password"
                        id="password"
                        value={pass}
                        autoComplete="current-password"
                        className={classes.input}
                        onBlur={() => pass === '' ? setCheckPass(false) : setCheckPass(true)}
                    />

                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={e => submit(e)}
                        disabled={loading?true:false}
                        className={loading ? classes.loading : classes.submit}
                    >
                        {
                            loading ? <CircularProgress className={classes.loader} color="inherit" /> : 'ورود'
                        }
                    </Button>

                </form>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
}